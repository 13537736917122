import axios from 'axios'
import { Base64 } from 'js-base64'
import Vue from 'vue'
import router from '../router'
import store from '../store'
import { Message } from 'element-ui'
import { refreshToken } from '@/lib/LibCommon'
axios.defaults.baseURL = process.env.VUE_APP_API_URL

axios.defaults.timeout = 10000

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'

// 添加请求拦截器
axios.interceptors.request.use(
  (config) => {
    const expireTime = parseInt(window.sessionStorage.getItem('REFRESH_TIME'))
    const refreshTokens = window.localStorage.getItem('REFRESH_TOKEN')
    if (expireTime && refreshTokens) {
      const left = new Date().getTime() - expireTime
      console.log(left)
      if (left >= 5 * 60 * 1000 && refreshTokens) {
        window.sessionStorage.setItem('REFRESH_TIME', 0)
        refreshToken(Vue.prototype)
      }
    }
    // 在请求之前做些什么
    const token = window.localStorage.getItem('TOKEN')
    if (token) {
      config.headers.token = 'Bearer ' + token
    }
    if (config.url != '/anno/login' && config.url != '/anno/captcha') {
      config.headers.tenant = window.localStorage.getItem('TENANT')
    }
    const clientId = 'pillar' // process.env.VUE_APP_CLIENT_ID;
    const clientSecret = 'pillar_secret' //process.env.VUE_APP_CLIENT_SECRET;
    config.headers['Authorization'] = `Basic ${Base64.encode(`${clientId}:${clientSecret}`)}`
    return config
  },
  (error) => {
    // 在请求错误之前做些什么
    return Promise.reject(error)
  }
)
let status = true
axios.interceptors.response.use(
  function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    return response
  },

  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    if (error.response.data.isSuccess === false) {
      let message = false
      // 未登录
      if (
        error.response.data.code === 40000 ||
        error.response.data.code === 40001 ||
        error.response.data.code === 40002 ||
        error.response.data.code === 40003 ||
        error.response.data.code === 40005 ||
        error.response.data.code === 40006 ||
        error.response.data.code === 40008 ||
        error.response.data.code == '-1'
      ) {
        if (status) {
          status = false
          Vue.prototype.$alert('登录超时，请重新登录！', '系统提示', {
            confirmButtonText: '确定',
            callback: (action) => {
              store.commit('SET_REQUEST_FIELD', '')
              window.localStorage.removeItem('USER')
              router.replace({
                path: '/login',
                query: {},
              })
              message = false
            },
          })
        }
      } else {
        // Vue.prototype.$message.error(error.response.data.msg)
      }
    }
    if (error.response.status) {
      switch (error.response.status) {
        // 400
        case 400: {
          // 如果错误信息中携带-1，表示被强制下线
          if (error.response.data.code == '40001') {
            Message.error('登录过期，请重新登录')
            // 清除token
            window.localStorage.removeItem('TOKEN')
            window.sessionStorage.removeItem('TOKEN')
            window.localStorage.removeItem('REFRESH_TOKEN')
            window.localStorage.removeItem('TENANT')
            window.localStorage.removeItem('TIME')
            window.localStorage.removeItem('USER')
            window.localStorage.removeItem('token')
            window.localStorage.removeItem('EQUIPMENT')

            store.commit('SET_LOGIN_TOKEN', null)
            // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
            setTimeout(() => {
              router.push({
                path: '/login',
              })
            }, 5000)
          } else if (error.response.data.code == '-9') {
            Vue.prototype.$message.error(error.response.data.msg)
            window.localStorage.removeItem('TOKEN')
            window.sessionStorage.removeItem('TOKEN')
            window.localStorage.removeItem('REFRESH_TOKEN')
            window.localStorage.removeItem('TENANT')
            window.localStorage.removeItem('TIME')
            window.localStorage.removeItem('USER')
            window.localStorage.removeItem('token')
            window.localStorage.removeItem('EQUIPMENT')
            store.commit('SET_REQUEST_FIELD', '')
            window.localStorage.removeItem('USER')
            router.replace({
              path: '/',
              query: {},
            })
            // store.commit('SET_LOGIN_TOKEN', '')
            // window.localStorage.removeItem('USER')
            // setTimeout(() => {
            //   router.push({
            //     path: '/login',
            //   })
            // }, 5000)
          } else {
            Message.error(error.response.data.msg)
          }
          break
        }
        default:
          Message.error(error.response.data.msg)
      }
    }
    // 对响应错误做点什么
    return Promise.reject(error)
  }
)

export function GET(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, { params: params })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}

export function POST(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}

export function DELETE(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .delete(url, params)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}
