export default {
  // 轮播图
  CAROUSEL_PICTURES: "/showScreenHome/searchSlideshowByUser",

  //大屏首页应用
  HOME_APPLICATION: "/showScreenHome/searchAppAllByUser",

  // 根据应用编码查询当前登录用户下级分类或者内容
  SEARCH_CATEGORY_OR_CONTENT: "/showScreenHome/searchCategoryByUserApp",

  // 根据分类查询下级分类（栏目）或内容
  SEARCH_CATEGORY_OR_CONTENT_BY_USER:
    "/showScreenCategory/searchCategoryContentByUser",

  // 党史上的今天
  TODAY_IN_PARTY_HISTORY: "/showScreenHome/searchTvshowRich",

  // 根据登录用户查询党组织概况数据
  SEARCH_PARTY_ORGANIZATION_OVERVIEW_BY_USER:
    "/showScreenHome/searchPartyOrganizationByUser",

  // 大屏根据应用id和地区查询红色基地分类或内容
  SEARCH_MAP_BY_APP_AND_AREA: "/showScreenHome/searchRedBaseByUserApp",

  // 根据内容ID获取内容
  GET_CONTENT_BY_ID: "/showScreenHome/searchCustomByCustom"
};
