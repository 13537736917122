import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

import { skin } from "@/store/module/skin";

const vuexLocal = new VuexPersistence({
  // 使用localStorage
  storage: window.localStorage,
  // 如果不用这个函指定，那么默认所有vuex数数都作持久化处理
  modules: ['skin']
});
export default new Vuex.Store({
  state: {
    LoginToken: "",
    keepAliveComponents: [] // 缓存数组
  },
  mutations: {
    SET_LOGIN_TOKEN: (state, data) => {
      state.LoginToken = data;
    },
    SET_KEEP_ALIVE(state, component) {
      // 注：防止重复添加（当然也可以使用Set）
      !state.keepAliveComponents.includes(component) &&
        state.keepAliveComponents.push(component)
    },
    REMOVE_KEEP_ALIVE(state, component) {
      const index = state.keepAliveComponents.indexOf(component)
      index !== -1 &&
        state.keepAliveComponents.splice(index, 1)
    }
  },
  actions: {},
  modules: {
    skin
  },
  plugins: [vuexLocal.plugin]
});
